export function dollarFigureToNumber(dollarFigure?: string | number): number {
  if (typeof dollarFigure === 'number') return dollarFigure;
  if (!dollarFigure) return 0;
  return Number(dollarFigure.replace(/[^0-9.-]+/g, ''));
}

export function formatAsDollarFigure(price: number): string {
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(!Number.isNaN(price) ? price : 0);
}
