import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TixEventsListComponent } from './events-list/events-list.component';
import {
  TixEventCalendarComponent,
  TixCalendarHeader
} from './event-calendar/event-calendar.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatNativeDateModule } from '@angular/material/core';
import {
  HeroClockIconSolid,
  HeroInfoIcon,
  HeroFilterIconSolid,
  HeroInformationCircle,
  HeroMapIconSolid,
  HeroSearchIconSolid,
  HeroShoppingCartSolid,
  TixButtonModule,
  TixCardModule,
  TixDialogModule,
  TixIconModule,
  TixIconRegistry,
  TixInputModule,
  HeroMail,
  HeroLink,
  TixAlertModule
} from '@tix/shared/ui/components';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TixVenueInfoComponent } from './venue-info/venue-info.component';
import { TixOrderItemsComponent } from './order-items/order-items.component';
import { VenueMapComponent } from './venue-map/venue-map.component';
import { DomSanitizer } from '@angular/platform-browser';
import { MatFormFieldModule } from '@angular/material/form-field';
import { EventYoutubeVideoComponent } from './event-youtube-video/event-youtube-video.component';
import { QuillModule } from 'ngx-quill';
import { TixDiscountCodeInputComponent } from './discountcode-input/discountcode-input.component';
import { BtnLoadingDirectiveModule } from '@tix/shared/ui/components';
import { TixCheckDiscountPasscodeComponent } from './check-discount-passcode/check-discount-passcode.component';
import { TixCheckEventPasscodeComponent } from './check-event-passcode/check-event-passcode.component';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BuyerProductCardComponent } from './product/buyer-product-card/buyer-product-card.component';
import { BuyerProductSwiperComponent } from './product/buyer-product-swiper/buyer-product-swiper.component';
import { MatDialogModule } from '@angular/material/dialog';
import { OrderSelectBaseComponent } from './order-select-base/order-select-base.component';
import { OrderHoldService } from '@tix/order-hold';
import { OrderQuantityCalculatorService } from '@tix/event-buyer/services';
import { TixInfoDialogModule } from '@tix/shared/ui/components';
import { TixDonationPopUp } from './donation-pop-up/donation-pop-up.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: TixEventCalendarComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    TixInfoDialogModule,
    TixInputModule,
    TixIconModule,
    TixButtonModule,
    RouterModule,
    TixCardModule,
    MatSelectModule,
    TixDialogModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatFormFieldModule,
    QuillModule,
    BtnLoadingDirectiveModule,
    TixAlertModule,
    TixCardModule,
    MatProgressSpinnerModule,
    MatDialogModule
  ],
  exports: [
    TixEventsListComponent,
    TixEventCalendarComponent,
    TixVenueInfoComponent,
    TixEventCalendarComponent,
    TixOrderItemsComponent
  ],
  declarations: [
    TixCheckDiscountPasscodeComponent,
    TixCheckEventPasscodeComponent,
    TixDiscountCodeInputComponent,
    VenueMapComponent,
    TixEventsListComponent,
    TixEventCalendarComponent,
    TixVenueInfoComponent,
    TixCalendarHeader,
    TixOrderItemsComponent,
    EventYoutubeVideoComponent,
    BuyerProductCardComponent,
    BuyerProductSwiperComponent,
    OrderSelectBaseComponent,
    TixDonationPopUp
  ],
  providers: [OrderHoldService]
})
export class EventBuyerComponentsModule {
  constructor(
    private iconRegistry: TixIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIconConfigs([
      HeroSearchIconSolid,
      HeroShoppingCartSolid,
      HeroClockIconSolid,
      HeroMapIconSolid,
      HeroInformationCircle,
      HeroInfoIcon,
      HeroFilterIconSolid,
      { ...HeroMail, name: 'email' },
      { ...HeroLink, name: 'url' }
    ]);
    iconRegistry.addSvgIcon(
      'facebook',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/brands/logo-facebook.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'apple',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/brands/apple.svg')
    );
    iconRegistry.addSvgIcon(
      'google',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/brands/google.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'ticket-info-icon',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/ticket-info-icon.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'twitter',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/brands/logo-twitter.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'youtube',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/brands/logo-youtube.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'tiktok',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/brands/tiktok.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'snapchat',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/brands/snapchat.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'linkedin',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/brands/linkedin.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'instagram',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/brands/logo-instagram.svg'
      )
    );
  }
}
