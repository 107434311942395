import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatDialogRef } from '@tix/shared/ui/components';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  TixBusinessCodeGroup,
  TixEvent,
  TixEventData,
  TixMediaFile,
  TixSocialMedia
} from '@tix/data-access';
import {
  BUSINESS_CODE_GROUP_NAMES,
  TixBusinessGroupsService
} from '@tix/shared/state';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { TixCompanyPartialState } from '@tix/company/state';
import * as TixEventsActions from '@tix/events/state';
import * as TixEventsSelectors from '@tix/events/state';
import * as moment from 'moment/moment';
import { Maybe } from 'graphql/jsutils/Maybe';
import { runInThisContext } from 'vm';
import { AmplitudeService } from '@tix/core/analytics';

@Component({
  selector: 'tix-event-multi-dialog',
  templateUrl: './event-multi-dialog.component.html',
  styleUrls: ['./event-multi-dialog.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class EventMultiDialogComponent implements OnInit, OnDestroy {
  readonly businessEventTypes$ =
    this.businessGroupsService.getBusinessGroupByName(
      BUSINESS_CODE_GROUP_NAMES.BUSINESS_EVENT_TYPE
    );
  readonly businessStatusLists$ =
    this.businessGroupsService.getBusinessGroupByName(
      BUSINESS_CODE_GROUP_NAMES.STATUS_TYPE
    );
  readonly businessSocialMediaTypes$ =
    this.businessGroupsService.getBusinessGroupByName(
      BUSINESS_CODE_GROUP_NAMES.SOCIAL_MEDIA_TYPE
    );
  readonly businessMediaFilesTypes$ =
    this.businessGroupsService.getBusinessGroupByName(
      BUSINESS_CODE_GROUP_NAMES.EVENT_MEDIA_FILE_TYPE
    );

  readonly eventVenueList$: Observable<any | undefined> = this.store.select(
    TixEventsSelectors.getVenueLists
  );
  readonly eventTicketConfigurationList$: Observable<any | undefined> =
    this.store.select(TixEventsSelectors.getVenueTicketLists);
  readonly getRecurringEventsLoading$: Observable<boolean | undefined> =
    this.store.select(TixEventsSelectors.getRecurringEventsLoading);
  readonly getRecurringEventsLoaded$: Observable<boolean | undefined> =
    this.store.select(TixEventsSelectors.getRecurringEventsLoaded);

  error$ = this.store.select(TixEventsSelectors.getEventsError);

  isLoadedSubscription: Subscription;
  errorSub?: Subscription;

  @Output()
  setCreatedEvent = new EventEmitter<any>();

  genericEventValidator: ValidationErrors | null = Validators.required;

  firstFormGroup = this._formBuilder.group({
    name: new FormControl('', Validators.required),
    type: new FormControl('', Validators.required),
    status: new FormControl('', [Validators.required]),
    description: new FormControl(''),
    additionalInfo: new FormControl(''),
    minAge: new FormControl(null),
    venueId: new FormControl(null, Validators.required),
    ticketConfigurationID: new FormControl(null, this.genericEventValidator),
    hidden: new FormControl(false),
    passcode: new FormControl('')
  });
  secondFormGroup = this._formBuilder.group({
    startDate: new FormControl('', Validators.required),
    endDate: new FormControl('', Validators.required),
    repeatsOnDay: new FormControl(null, Validators.required),

    instanceTime: new FormArray([]),
    dayTimes: new FormArray([
      new FormGroup({
        startTime: new FormControl(''),
        endTime: new FormControl(''),
        doorsOpen: new FormControl('')
      })
    ])
  });

  thirdFormGroup = this._formBuilder.group({
    socialMediasForm: setupSocialMediasForm([]),
    mediasForm: setupMediasForm([])
  });

  forms: FormGroup[] = [];
  currentStep = 0;

  showLoadingCallout = false;
  isLoading = false;

  hadError = false;

  constructor(
    public dialogRef: MatDialogRef<EventMultiDialogComponent>,
    private _formBuilder: FormBuilder,
    private readonly store: Store<TixCompanyPartialState>,
    private _snackbar: MatSnackBar,
    private businessGroupsService: TixBusinessGroupsService,
    private amplitudeService: AmplitudeService
  ) {}

  ngOnInit(): void {
    const { ticketConfigurationID } = this.firstFormGroup.controls;
    const controls = [ticketConfigurationID];

    this.firstFormGroup.get('type')?.valueChanges.subscribe(value => {
      if (value === 'Event') {
        this.genericEventValidator = null;
        controls.forEach(control => {
          if (control) {
            control.clearValidators();
            control.disable();
            control.updateValueAndValidity();
          }
        });
      } else {
        this.genericEventValidator = Validators.required;
        controls.forEach(control => {
          if (control) {
            control.setValidators(Validators.required);
            control.enable();
            control.updateValueAndValidity();
          }
        });
      }
    });

    this.forms = [
      this.firstFormGroup,
      this.secondFormGroup,
      this.thirdFormGroup
    ];

    this.store.dispatch(TixEventsActions.getVenueList());

    let previousVenueId = this.firstFormGroup.controls.venueId.value;

    this.firstFormGroup.valueChanges.subscribe(val => {
      if (val.venueId === previousVenueId) return;

      previousVenueId = this.firstFormGroup.controls.venueId.value;

      this.store.dispatch(
        TixEventsActions.getVenueTicketConfigurationList({
          venueId: previousVenueId
        })
      );
    });
  }

  submit() {
    this.amplitudeService.trackEvent('Button Submitted: Create Many Events');

    const secondFormValue = { ...this.secondFormGroup.value };
    delete secondFormValue.dayTimes;
    delete secondFormValue.repeatsOnDay;

    secondFormValue.instanceTime = secondFormValue.instanceTime.map(
      (time: any) => ({
        ...time,
        startTime: time.startTime || null,
        endTime: time.endTime || null,
        doorsOpen: time.doorsOpen || null
      })
    );

    const firstFormValue = this.genericEventValidator
      ? { ...this.firstFormGroup.value }
      : { ...this.firstFormGroup.value, ticketConfigurationID: null };

    const recurringEvent: TixEventData = {
      ...firstFormValue,
      ...secondFormValue,
      ...this.thirdFormGroup.controls.socialMediasForm.value,
      ...this.thirdFormGroup.controls.mediasForm.value,
      minAge: this.firstFormGroup.value.minAge
        ? parseInt(this.firstFormGroup.value.minAge)
        : null,
      instanceTime: secondFormValue.instanceTime.map((time: any) => ({
        ...time,
        date: this.formatDate(time.date)
      }))
    };
    delete (recurringEvent as any).startDate;
    delete (recurringEvent as any).endDate;

    this.store.dispatch(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      TixEventsActions.addRecurringEventInCompany({
        addRecurringEventObj: {
          ...recurringEvent
        }
      })
    );

    this.checkLoadingStatus({
      ...recurringEvent,
      startDate: this.secondFormGroup.get('startDate')?.value,
      endDate: this.secondFormGroup.get('endDate')?.value
    });
  }

  private formatDate(date: Date) {
    return moment(date).format('MM/DD/YYYY');
  }

  goNext() {
    this.currentStep = Math.min(this.forms.length - 1, this.currentStep + 1);
  }

  checkLoadingStatus(ev: any) {
    this.showLoadingCallout = false;
    this.isLoading = true;
    this.isLoadedSubscription?.unsubscribe();
    this.errorSub?.unsubscribe();

    setTimeout(() => {
      this.showLoadingCallout = true;
    }, 3000);

    this.error$.subscribe(error => {
      if (error) {
        this._snackbar.open('An error has ocurred', 'Close', {
          duration: 3000
        });
        this.isLoading = false;
        this.setCreatedEvent.emit(undefined);
        this.hadError = true;
      }
      this.errorSub?.unsubscribe();
    });

    this.isLoadedSubscription = this.getRecurringEventsLoaded$.subscribe(
      isLoaded => {
        if (isLoaded) {
          this.isLoading = false;
          this.dialogRef.close();
          if (!this.hadError) {
            console.log(ev);
            this.setCreatedEvent.emit(ev);
          }
        }
      }
    );
  }

  goPrevious() {
    this.currentStep = Math.max(0, this.currentStep - 1);
  }
  get isLastStep() {
    return this.currentStep === this.forms.length - 1;
  }
  get isFirstStep() {
    return this.currentStep === 0;
  }

  ngOnDestroy() {
    this.errorSub?.unsubscribe();
    this.isLoadedSubscription?.unsubscribe();
  }
}

/**
 * Social media part
 */

export type SocialMediaValue = {
  socialMediaUrl: any;
  socialMediaType: any;
  socialMediaId: any;
};

function formatSocialMediaValue(socialMedia: SocialMediaValue) {
  return {
    url: socialMedia.socialMediaUrl,
    socialMediaId: socialMedia.socialMediaId,
    socialMediaType: socialMedia.socialMediaType
  } as TixSocialMedia;
}

function setupSocialMediasForm(socialMedias: Array<Maybe<TixSocialMedia>>) {
  const socialMediasForm = new FormGroup({
    socialMedia: createSocialMediaArray(socialMedias)
  });

  if (socialMedias.length > 0) {
    (socialMediasForm.get('socialMedia') as FormArray).clear();
    socialMedias.forEach(socialMedia => {
      (socialMediasForm.get('socialMedia') as FormArray).push(
        createSocialMediaFormGroup(socialMedia)
      );
    });
  }

  return socialMediasForm;
}

function setupMediasForm(medias: Array<Maybe<TixMediaFile>>) {
  const mediasForm = new FormGroup({
    media: createMediaArray(medias)
  });
  if (medias.length > 0) {
    (mediasForm.get('media') as FormArray).clear();
    medias.forEach(media => {
      (mediasForm.get('media') as FormArray).push(createMediaFormGroup(media));
    });
  }

  return mediasForm;
}

function createSocialMediaArray(socialMedias: Array<Maybe<TixSocialMedia>>) {
  if (!socialMedias || socialMedias.length === 0)
    return new FormArray([createSocialMediaFormGroup()]);
  return new FormArray(
    socialMedias.map(socialMedia => createSocialMediaFormGroup(socialMedia))
  );
}

function createMediaArray(medias: Array<Maybe<TixMediaFile>>) {
  if (!medias || medias.length === 0)
    return new FormArray([createMediaFormGroup()]);
  return new FormArray(medias.map(media => createMediaFormGroup(media)));
}

function createSocialMediaFormGroup(
  socialMedia?: Maybe<TixSocialMedia>
): FormGroup {
  return new FormGroup({
    url: new FormControl(socialMedia?.url ?? ''),
    socialMediaType: new FormControl(socialMedia?.socialMediaType ?? '')
    // socialMediaId: new FormControl(socialMedia?.socialMediaId ?? '')
  } as any);
}
function createMediaFormGroup(media?: Maybe<TixMediaFile>): FormGroup {
  return new FormGroup({
    location: new FormControl(media?.location ?? ''),
    fileType: new FormControl(media?.fileType ?? ''),
    name: new FormControl(media?.name ?? '')
  });
}
