import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { dollarFigureToNumber } from '@tix/shared/utils';
@Component({
  selector: 'tix-donation-pop-up',
  templateUrl: './donation-pop-up.component.html'
})
export class TixDonationPopUp implements OnInit, OnDestroy {
  @Input() DonationData: [];
  @Input() eventInstanceId: string;
  @Input() contactId: string;
  @Output() closeDialog = new EventEmitter<boolean>();
  @Output() addDonationAmount = new EventEmitter<{} | null>();
  defaultAmountRegex = '^(\\$)?(\\d{1,3}(,\\d{3})*(\\d+)?(\\.\\d{2})?)?$';
  donationPurposeID = '96dfab8c-3d91-4e9d-95dd-414b071aec14';
  donationDefaultAmountID = '4861b915-1698-4723-a316-82b4ce826f1c';
  donationAmountControl = new FormControl(null, [
    Validators.pattern(this.defaultAmountRegex),
    Validators.required
  ]);
  donationPurposeText = '';
  constructor() {}
  ngOnInit(): void {
    this.DonationData.map((configData: any) => {
      if (
        configData.value.configurationItemId == this.donationDefaultAmountID
      ) {
        this.donationAmountControl.setValue(configData.value.value);
      }
      if (configData.value.configurationItemId == this.donationPurposeID) {
        this.donationPurposeText = configData.value.value;
      }
    });
    console.log(this.donationAmountControl);
  }
  handleCancel() {
    this.addDonationAmount.emit(null);
    this.closeDialog.emit(false);
  }

  handleDeny() {
    this.addDonationAmount.emit({
      donationAmount: 0,
      donationPurpose: this.donationPurposeText
    });
    this.closeDialog.emit(true);
  }

  handleAddDonation() {
    if (this.donationAmountControl.errors) {
      return;
    }
    this.addDonationAmount.emit({
      donationAmount: dollarFigureToNumber(this.donationAmountControl.value),
      donationPurpose: this.donationPurposeText
    });
    this.closeDialog.emit(true);
  }

  ngOnDestroy(): void {}
}
