<tix-card class="bg-white px-4 !min-w-[35vw] py-4 lg:px-8 lg:py-8">
  <tix-card-title class="text-center">
    <h2>Would you like to make a donation ?</h2>
  </tix-card-title>
  <div class="text-center text-gray-400 text-sm mt-4 mb-4">
    <p class="mt-4 mb-4">This donation is for {{ donationPurposeText }}</p>
    <p class="mt-4 mb-4">
      You can use the default amount below or change it to an amount you'd like
      to donate
    </p>
  </div>
  <div>
    <form>
      <mat-form-field class="!text-center !font-medium">
        <mat-label>Donation amount:</mat-label>
        <input
          matInput
          type="text"
          placeholder="donation amount in $"
          [formControl]="donationAmountControl"
        />

        <mat-error *ngIf="donationAmountControl.hasError"
          >this is invalid input</mat-error
        >
      </mat-form-field>
      <div
        class="flex justify-center sm:justify-end gap-2 mt-2 flex-wrap sm:flex-nowrap"
      >
        <button
          tix-flat-border-button
          color="primary"
          class="font-bold !px-2 sm:!px-4 w-full sm:w-fit"
          (click)="handleCancel()"
        >
          Cancel
        </button>
        <button
          tix-flat-border-button
          color="primary"
          class="font-bold !px-2 sm:!px-4 w-full sm:w-fit"
          (click)="handleDeny()"
        >
          No Thank You
        </button>
        <button
          tix-button
          (click)="handleAddDonation()"
          class="font-bold !px-2 sm:!px-4 w-full sm:w-fit"
        >
          Add donation
        </button>
      </div>
    </form>
  </div>
</tix-card>
