<tix-form-general-additionnal-config
  [groupType]="'Venue'"
  [allInputValues]="allInputValues"
  (sendData)="receiveConfigFormFromChild($event)"
>
</tix-form-general-additionnal-config>
<div class="mt-8 flex justify-end" *ngIf="editPermission">
  <button
    type="submit"
    [disabled]="configFormChild.invalid || isSaving || configFormChild.pristine"
    tix-button
    (click)="onSubmit()"
  >
    {{ isSaving ? 'Saving...' : 'Save' }}
  </button>
</div>
