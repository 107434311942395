<form [formGroup]="configurationForm" (submit)="onSubmit($event)" class="form">
  <ng-container *ngIf="configItems$ | async as configItemService">
    <div *ngFor="let item of sortedData">
      <ng-container>
        <div
          *ngIf="
            sortedData.indexOf(item) === 0 ||
            item?.GroupItem?.Group?.name !==
              sortedData[
                sortedData.indexOf(item) <= 0 ? 0 : sortedData.indexOf(item) - 1
              ].GroupItem?.Group?.name
          "
        >
          <tix-card-subtitle class="mb-3 mt-2 font-semibold">{{
            item?.GroupItem?.Group?.name
          }}</tix-card-subtitle>
        </div>
        <div
          *ngIf="
            item.GroupItem?.configurationGroupId == EventManagmentGroupId &&
              item.configurationItemId != checkBoxEmailNotificationId &&
              item.configurationItemId != emailAdressNotificationId;
            else InputComponent
          "
        >
          <ng-content select="tix-event-report-subscription"></ng-content>
        </div>
        <ng-template #InputComponent>
          <div
            *ngIf="item.elementType === 'Boolean'"
            class="self-center flex justify-start mr-auto mb-2 md:mb-0"
          >
            <tix-checkbox
              [formControlName]="item.configurationItemId"
              [labelPosition]="'before'"
            >
              {{ item.name }}
              <tix-icon
                *ngIf="item.helpText"
                [matTooltip]="item.helpText"
                class="ml-2 !w-5 !h-5 translate-y-1"
                svgIcon="information-circle"
              ></tix-icon>
              <mat-error
                *ngIf="
                  configurationForm?.get(item.configurationItemId)?.touched &&
                  configurationForm?.get(item.configurationItemId)?.errors
                "
              >
                Invalid Input. Please ensure that the checkbox is
                selected.</mat-error
              >
            </tix-checkbox>
          </div>

          <div *ngIf="item.elementType !== 'Boolean'">
            <mat-form-field>
              <mat-label>{{ item.name }}</mat-label>

              <input
                [type]="
                  item.elementType === 'Money' || item.elementType === 'Percent'
                    ? 'number'
                    : 'text'
                "
                matInput
                [formControlName]="item.configurationItemId"
                [placeholder]="item.placeholder ?? ''"
              />
              <mat-error
                *ngIf="
                  configurationForm?.get(item.configurationItemId)?.touched &&
                  configurationForm?.get(item.configurationItemId)?.errors
                "
              >
                Invalid Input. See help text for more detail.</mat-error
              >
            </mat-form-field>
            <mat-hint class="text-[0.8em] mt-0 ml-3 relative top-[-0.6em]">{{
              item.helpText
            }}</mat-hint>
          </div>
        </ng-template>
      </ng-container>
    </div>
  </ng-container>
</form>
