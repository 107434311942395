import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { UserPartialState, UserSelector } from '@tix/auth/state';
import { TixCompany, TixUserRole } from '@tix/data-access';
import { ROLE_TYPES } from 'libs/auth/state/src/lib/+state/user.model';
import { ReleaseRedirectService } from '@tix/auth/services';
import { Log, LogsState, NetworkCall } from '../../state/log.state';
import { selectLogs, selectNetworkCalls } from '../../state/log.selectors';
import {
  FeedbackService,
  TixFeedbackCategory
} from '../../services/feedback/feedback.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '@tix/shared/models';
import * as CompanySelectors from '@tix/company/state/selectors';
import { HttpClient, HttpEventType } from '@angular/common/http';

export enum FEEDBACK_CATEGORY {
  BUG_REPORT = 'Bug Report',
  FEATURE_REQUEST = 'Feature Request',
  SUGGESTED_IMPROVEMENT = 'Suggested Improvement',
  OTHER = 'Other'
}

@Component({
  selector: 'tix-company-layout',
  templateUrl: './company-layout.component.html',
  styleUrls: ['./company-layout.component.scss']
})
export class TixCompanyLayoutComponent implements OnInit, OnDestroy {
  readonly UserRoles$: Observable<any> = this.store.select(
    UserSelector.getUserRolesInfo
  );
  readonly authenticatedUser$: Observable<User | null> = this.store.select(
    UserSelector.getAuthenticatedUser
  );

  readonly selectedCompany$: Observable<TixCompany | undefined> =
    this.store.select(CompanySelectors.getSelectedCompany);

  feedbackForm: FormGroup;

  feedbackCategories = Object.entries(FEEDBACK_CATEGORY).map(
    ([key, value]) => ({
      value: key,
      label: value
    })
  );

  readonly logs$: Observable<Log[]> = this.store.select(selectLogs);
  readonly networks$: Observable<NetworkCall[]> =
    this.store.select(selectNetworkCalls);

  userRoles: TixUserRole[] = [];
  authenticatedUser: User | null;
  selectedCompany: TixCompany | undefined;
  companyId = '';

  selectedFiles: { file: File; preview: string; name: string }[] = [];
  expandSidebar = true;

  isLoading = true;

  isSubmitting: boolean = false;
  submittingError: boolean = false;

  isMoreThanOneVideoSelected: boolean = false;
  isVideoSizeExceeded: boolean = false;

  feedbackFormSubmited = false;
  showPopup = false;

  constructor(
    private router: Router,
    private readonly store: Store<UserPartialState>,
    private releaseService: ReleaseRedirectService,
    private feedbackService: FeedbackService
  ) {}

  get companyName(): string {
    return this.selectedCompany?.name!;
  }

  get userId(): string {
    return this.authenticatedUser?.uid!;
  }
  get userEmail(): string {
    return this.authenticatedUser?.email!;
  }

  async ngOnInit() {
    this.companyId = this.router.url.split('/')[2];

    await this.checkReleaseAndRedirect();

    this.feedbackForm = new FormGroup({
      description: new FormControl('', [Validators.required]),
      category: new FormControl('', [Validators.required]),
      title: new FormControl('', [Validators.required]),
      attachments: new FormControl(null)
    });

    this.UserRoles$.subscribe(userRoles => {
      this.userRoles = userRoles?.userRoles;
    });
    this.authenticatedUser$.subscribe(user => {
      this.authenticatedUser = user;
    });

    this.selectedCompany$.subscribe(company => {
      this.selectedCompany = company;
    });
  }

  async checkReleaseAndRedirect() {
    this.isLoading = true;
    const { willRedirect } = await this.releaseService
      .checkAndRedirect(this.companyId)
      .toPromise();

    // We will keep the app in a loading state until the redirection happens
    if (willRedirect) return;

    this.isLoading = false;
  }

  togglePopup(): void {
    this.showPopup = !this.showPopup;
  }

  get categoryControl() {
    return this.feedbackForm.get('category');
  }
  get titleControl() {
    return this.feedbackForm.get('title');
  }
  get descriptionControl() {
    return this.feedbackForm.get('description');
  }

  async submitFeedback(): Promise<void> {
    this.isSubmitting = true;

    if (this.feedbackForm.valid) {
      const formValue = this.feedbackForm.value;
      const attachments = formValue.attachments;
      const title: string = formValue.title;
      const description: string = formValue.description;
      const category: string = formValue.category;

      const additionalInfos = {
        companyName: this.companyName,
        userId: this.userId,
        userEmail: this.userEmail
      };

      const feedback = {
        title,
        description,
        category: category as TixFeedbackCategory
      };

      let logFile = null;
      let networkFile = null;

      if (category === 'BUG_REPORT') {
        const logs = await this.logs$.pipe(take(1)).toPromise();
        const networkLogs = await this.networks$.pipe(take(1)).toPromise();

        logFile = this.createConsoleLogTextFile(logs);
        networkFile = this.createNetworkLogTextFile(networkLogs);
      }

      const feedbackAttachments = await this.uploadAttachments(attachments);

      this.feedbackService
        .submitFeedback(
          feedbackAttachments,
          feedback,
          additionalInfos,
          logFile,
          networkFile
        )
        .pipe(tap(console.log))
        .subscribe(
          event => {
            if (event.type === HttpEventType.Response) {
              if (event.status >= 200 && event.status < 300) {
                if (event.body?.data?.submitFeedback) {
                  this.feedbackFormSubmited = true;
                  this.feedbackForm.reset();
                } else {
                  this.submittingError = true;
                  this.isSubmitting = false;
                }
              }
            }
          },
          err => {
            console.log('error while upload: ', err);
            this.isSubmitting = false;
            this.submittingError = true;
          }
        );
    }
  }

  async uploadAttachments(attachments: File[]) {
    if (attachments && attachments.length > 0) {
      const uploadedFiles = [];

      for (const attachment of attachments) {
        try {
          const uploadedVideoLink = await this.feedbackService
            .uploadFeedbackVideo(attachment)
            .toPromise();

          if (uploadedVideoLink) {
            uploadedFiles.push({
              url: uploadedVideoLink,
              filename: attachment.name
            });
          }
        } catch (error) {
          console.error(`Error uploading file: ${attachment.name}`, error);
        }
      }

      return uploadedFiles;
    }
    return null;
  }

  videoCount = 0;

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    const files = input.files;

    this.isMoreThanOneVideoSelected = false;
    this.isVideoSizeExceeded = false;

    if (files && files.length > 0) {
      for (const file of Array.from(files)) {
        const isDuplicate = this.selectedFiles.some(
          selectedFile =>
            selectedFile.file.name === file.name &&
            selectedFile.file.size === file.size
        );

        if (isDuplicate) {
          continue;
        }

        if (file.type.startsWith('video/')) {
          this.videoCount++;
          if (file.size > 10 * 1024 * 1024) {
            this.isVideoSizeExceeded = true;
          }
          const previewUrl = URL.createObjectURL(file);
          this.selectedFiles.push({
            file,
            preview: previewUrl,
            name: file.name
          });
        } else {
          const reader = new FileReader();
          reader.onload = (e: ProgressEvent<FileReader>) => {
            this.selectedFiles.push({
              file,
              preview: e.target?.result as string,
              name: file.name
            });
          };
          reader.readAsDataURL(file);
        }
      }

      if (this.videoCount > 1) this.isMoreThanOneVideoSelected = true;
      this.feedbackForm.controls['attachments'].patchValue(Array.from(files));
      this.feedbackForm.updateValueAndValidity();
    }
  }

  isImage(file: { file: File; preview: string }): boolean {
    return file.file.type.startsWith('image/');
  }

  removeFile(index: number): void {
    if (this.selectedFiles[index].file.type.startsWith('video/')) {
      this.videoCount--;
      if (this.videoCount <= 1) this.isMoreThanOneVideoSelected = false;
      if (this.selectedFiles[index].file.size > 10 * 1024 * 1024) {
        this.isVideoSizeExceeded = false;
      }
    }
    this.selectedFiles.splice(index, 1);
  }

  createNetworkLogTextFile(logs: NetworkCall[]): File {
    const logFileName = `network-logs-${new Date().toISOString()}.txt`;
    const logText = logs
      .map(log => {
        const formattedBody = log.body ? JSON.stringify(log.body) : null;
        return `[${log.timestamp.toISOString()}] | ${log.method} | ${
          log.url
        } | [${log.status}] ${log.duration}ms | body: ${formattedBody}`;
      })
      .join('\n');

    const blob = new Blob([logText], { type: 'text/plain' });
    const file = new File([blob], logFileName, { type: 'text/plain' });

    return file;
  }

  createConsoleLogTextFile(logs: Log[]): File {
    const logFileName = `console-logs-${new Date().toISOString()}.txt`;

    const logText = logs
      .map(log => {
        const formattedMessage = log.message
          .map(msg => (typeof msg === 'object' ? JSON.stringify(msg) : msg))
          .join(' ');

        return `[${log.timestamp.toISOString()}] [${
          log.type
        }] ${formattedMessage}`;
      })
      .join('\n');

    const blob = new Blob([logText], { type: 'text/plain' });
    const file = new File([blob], logFileName, { type: 'text/plain' });

    return file;
  }

  newFeedback() {
    this.feedbackFormSubmited = false;
    this.isSubmitting = false;
    this.selectedFiles = [];
  }

  ngOnDestroy(): void {
    this.selectedFiles.forEach(file => {
      if (file.preview.startsWith('blob:')) {
        URL.revokeObjectURL(file.preview);
      }
    });
  }
}
