import { navigation } from '@nrwl/angular';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Maybe,
  TixCompanyAddress,
  TixGetDiscountInfoQuery,
  TixGetDonationSetUpByEventIdGQL,
  TixGetItemsAmountsSoldGQL,
  TixVenue
} from '@tix/data-access';
import { TixDialog } from '@tix/shared/ui/components';
import {
  BUSINESS_CODE_GROUP_NAMES,
  TixBusinessGroupsService
} from '@tix/shared/state';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  TixFeesService,
  dollarFigureToNumber,
  formatAsDollarFigure
} from '@tix/shared/utils';
import { BuyerProductService } from '../product/services/buyer-product.service';

import {
  OrderStateManagerService,
  OrderTicket,
  PortalCheckoutService,
  PortalEvent,
  PortalVenueAddress,
  PortalVenueInfo,
  QuantityPool
} from '@tix/event-buyer/services';
import { TixCheckEventPasscodeComponent } from '../check-event-passcode/check-event-passcode.component';
import { TixInfoDialogComponent } from 'libs/shared/ui/components/src/lib/dialog/info-dialog/info-dialog.component';
import { OrderSelectBaseComponent } from '../order-select-base/order-select-base.component';
import { TicketConfiguration } from '@tix/event-buyer/state';
import { TixDonationPopUp } from '../donation-pop-up/donation-pop-up.component';
import { AmplitudeService } from '@tix/core/analytics';
import { DonationService } from '../services/donation.service';

function firstOrNull<T>(array: Array<T> = []): T | null {
  return array[0] ?? null;
}

@Component({
  selector: 'tix-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [OrderStateManagerService]
})
export class TixEventsListComponent
  extends OrderSelectBaseComponent
  implements OnInit, OnDestroy, OnChanges
{
  getEventId(): string {
    return this.event.eventInstanceId;
  }

  @Input() event: PortalEvent;
  @Input() venueDetail: Maybe<TixVenue | PortalVenueInfo>;
  @Input() venueId: string;

  public toggleAccordian = false;
  public isDisable = false;

  public errorMessage = '';
  public availableSeatCount: number;

  tickets: OrderTicket[] = [];

  ticketMinValue: number;
  ticketMaxValue: number;
  donationAmount: number;
  donationComment: string;
  comments = new FormControl();
  parseFloat = parseFloat;

  sub?: Subscription;

  get isGenericEvent() {
    return this.event.eventType === 'Event';
  }

  eTicketMessage = this.businessGroupsService
    .getBusinessGroupByName(BUSINESS_CODE_GROUP_NAMES.PORTAL_MESSAGES)
    .pipe(
      map(
        group =>
          group?.businessCodes.find(code => code.name === 'eticket_message')
            ?.description
      )
    );

  appliedDiscount?: TixGetDiscountInfoQuery['Discount'][0];
  invalidDiscount = false;
  discountError: string;
  isDonationAllowedConfigId = '842dc2a0-092d-4308-a274-b66e4b78aed9';

  private passcodeVerified = false;

  constructor(
    private _buyerProductService: BuyerProductService,
    private router: Router,
    private route: ActivatedRoute,
    private zone: NgZone,
    private businessGroupsService: TixBusinessGroupsService,
    private feesService: TixFeesService,
    private snackBar: MatSnackBar,
    private dialog: TixDialog,
    private getDonationSetUpByEventId: TixGetDonationSetUpByEventIdGQL,
    private changeDetector: ChangeDetectorRef,
    private portalCheckoutService: PortalCheckoutService,
    orderStateManager: OrderStateManagerService,
    getAmountsSoldQuery: TixGetItemsAmountsSoldGQL,
    private amplitudeService: AmplitudeService,
    private donationService: DonationService
  ) {
    super(orderStateManager, getAmountsSoldQuery, changeDetector);
  }

  public getRoute() {
    return this.route;
  }
  public getRouter() {
    return this.router;
  }

  toDecimal(_money: number) {
    if (_money == undefined) {
      return 0;
    }
    const [first, second] = _money.toString().split('.');
    return +second > 0 ? _money : first;
  }

  get onlineConfigurations() {
    return this.tickets
      .filter(config => {
        return (
          !config.salesChannel ||
          ['both', 'online'].includes(config.salesChannel.toLowerCase())
        );
      })
      .sort(
        (config1, config2) =>
          this.dollarFigureToNumber(config1.ticketPrice.replace('$', '')) -
          this.dollarFigureToNumber(config2.ticketPrice.replace('$', ''))
      );
  }

  get validTickets() {
    return this.onlineConfigurations.filter(
      config => !this.configurationIsExpired(config)
    );
  }

  openDonationDialog(donationData: []): Promise<boolean> {
    return new Promise(resolve => {
      const dialog = this.dialog.open(TixDonationPopUp);
      dialog.componentInstance.DonationData = donationData;
      dialog.componentInstance.eventInstanceId = this.getEventId();
      dialog.componentInstance.closeDialog.subscribe(valid => {
        dialog.close(valid);

        if (valid) {
          this.navigateToNextPage();
        }
      });
      dialog.componentInstance.addDonationAmount.subscribe((value: any) => {
        this.donationAmount = value?.donationAmount;
        this.donationComment = value?.donationPurpose;
      });
      dialog.afterClosed().subscribe(val => {
        resolve(val);
      });
    });
  }

  configurationIsExpired(config: Partial<TicketConfiguration>) {
    if (!config.stopSellingDate) return false;

    const currentTime = moment();
    const stopSellingDateTime = moment(
      `${config.stopSellingDate} ${config.stopSellingTime}`,
      'YYYY-MM-DD hh:mm:ss A'
    );
    return stopSellingDateTime.isBefore(currentTime);
  }

  get showIsExpired(): boolean {
    return this.validTickets.length === 0;
  }

  isEventStopSellingDateTime(): boolean {
    if (!this.event.stopSellingDate || !this.event.stopSellingTime) {
      return false;
    }
    const currentTime = moment();
    const stopSellingDateTime = moment(
      `${this.event.stopSellingDate} ${this.event.stopSellingTime}`,
      'YYYY-MM-DD hh:mm:ss A'
    );
    return stopSellingDateTime.isBefore(currentTime);
  }

  isEventDateTime(): boolean {
    if (!this.event.date || !this.event.startTime) return false;

    const currentTime = moment();
    const eventDateTime = moment(
      `${this.event.date} ${this.event.startTime}`,
      'YYYY-MM-DD hh:mm:ss A'
    );
    return eventDateTime.isBefore(currentTime);
  }

  get showIsPassed(): boolean {
    return this.isEventDateTime() || this.isEventStopSellingDateTime();
  }

  get displayPrice() {
    if (this.showIsExpired) {
      return 'Expired';
    }

    let priceRange;
    if (this.ticketMaxValue) {
      priceRange =
        '$' +
        this.toDecimal(this.ticketMinValue) +
        ' - $' +
        this.toDecimal(this.ticketMaxValue);
    } else {
      priceRange = '$' + this.toDecimal(this.ticketMinValue);
    }
    return this.showIsSoldOut
      ? 'sold out'
      : !dollarFigureToNumber(this.ticketMinValue?.toString())
      ? 'FREE'
      : priceRange;
  }

  get eventImage(): Maybe<any> {
    return (
      this.mediaList.find(
        media => media.mediaFile.fileType?.toLowerCase() === 'event image'
      )?.mediaFile.location ??
      'https://asylumtixmedia.sfo3.digitaloceanspaces.com/asylumTix/default_images/ticket_logo.jpg'
    );
  }

  get venueAddress(): Maybe<PortalVenueAddress> {
    return firstOrNull(this.venueDetail?.addresses);
  }

  get companyAddress(): Maybe<TixCompanyAddress> {
    return firstOrNull(
      this.venueDetail?.company?.addresses as TixCompanyAddress[]
    );
  }

  get totalAmount() {
    return this.tickets.reduce(
      (acc, curr) =>
        acc +
        this.dollarFigureToNumber(curr.ticketPrice) * (curr.ticketQty || 0),
      0
    );
  }

  get totalTickets() {
    return this.orderStateManager
      .getOrderItems()
      .reduce(
        (total, curr) =>
          total + this.orderStateManager.getQuantitySelectedForItem(curr.id),
        0
      );
  }

  onDiscountErrorUpdate(error: string): void {
    this.discountError = error;
  }

  getTooltipText(item: any): string {
    const minOrder = item.minPerOrder ?? 1;
    const maxOrder = this.orderStateManager.getMaxSelectableForItem(
      item.ticketConfigId
    );
    return `Minimum per order: ${minOrder}, Max: ${maxOrder}`;
  }

  openInfoDialog(header: string, text: string) {
    const dialog = this.dialog.open(TixInfoDialogComponent);
    dialog.componentInstance.header = header;
    dialog.componentInstance.text = text;
    dialog.componentInstance.closeDialog.subscribe(val => {
      dialog.close();
    });
  }

  isDonationActiveForEvent(): Promise<any> {
    return this.getDonationSetUpByEventId
      .fetch({ eventInstanceId: this.getEventId() })
      .toPromise();
  }

  async checkOut() {
    const isValid = await this.validateOrder();
    if (!isValid) {
      this.snackBar.open(
        'The tickets selected are no longer available, please try selecting the tickets again.'
      );
      return;
    }
    await this.orderStateManager.updateOrderOnHold();
    await this.orderStateManager.persistOrder();

    this.isDisable = false;

    if (this.totalTickets === 0) {
      this.isDisable = true;
      this.errorMessage = 'Must select one quantity.';
    } else {
      this.amplitudeService.trackEvent('Button Clicked: checkOut Button', {
        ticketCount: this.totalTickets,
        eventInstanceId: this.event.eventInstanceId
      });
      this.isDisable = false;
      this.errorMessage = '';

      this.portalCheckoutService.persistOrderInfo({
        products: [],
        tickets: this.tickets,
        discount: this.appliedDiscount,
        total: formatAsDollarFigure(this.totalAmount),
        eventInstanceId: this.event.eventInstanceId
      });

      this.addDonationAmount();
    }
  }

  navigateToNextPage() {
    this._buyerProductService
      .getAddonsForEvent(this.event.eventInstanceId)
      .then(async res => {
        const isExists = res.length > 0;

        this.changeDetector.markForCheck();

        if (!isExists) this.#proceedToSummary();
        else this.#proceedToAddons();
      });
  }

  async addDonationAmount() {
    const donationConfigItems = await this.isDonationActiveForEvent().then(
      (res: any) => {
        return res?.data?.EventInstanceConfigurationValue;
      }
    );
    console.log('donation', donationConfigItems);
    if (donationConfigItems.length > 0) {
      let allowDonation = false;
      for (const configItem of donationConfigItems) {
        if (
          configItem.value?.configurationItemId ===
          this.isDonationAllowedConfigId
        ) {
          if (configItem.value?.value === 'true') {
            allowDonation = true;
            const isDialogClosed = await this.openDonationDialog(
              donationConfigItems
            );
            console.log('dialog is closed', isDialogClosed);
            if (isDialogClosed) {
              this.donationService.setDonation({
                donation: this.donationAmount ?? 0,
                comment: this.donationComment ?? ''
              });

              this.isDisable = true;
              this.navigateToNextPage();
              return;
            }
          } else {
            this.donationService.setDonation({
              donation: this.donationAmount ?? 0,
              comment: this.donationComment ?? ''
            });
            this.navigateToNextPage();
            return;
          }
        }
      }
    } else {
      this.donationService.setDonation({
        donation: this.donationAmount ?? 0,
        comment: this.donationComment ?? ''
      });
      this.navigateToNextPage();
    }
  }

  #proceedToAddons() {
    this.router.navigate([
      'venue',
      this.venueId,
      'addons',
      this.event.eventInstanceId
    ]);
  }

  #proceedToSummary($event: void) {
    this.zone.run(() => {
      this.router.navigate(['/checkout/' + this.venueId]);
      this.isDisable = false;
    });
  }

  get availableSeats(): any {
    return this.event.Metrics?.available;
  }

  get totalSeats(): any {
    return this.event.Metrics?.total_seats;
  }

  get warningsLength(): number {
    return this.venueDetail?.warnings?.length ?? 0;
  }

  get socialMediaList() {
    return (
      this.event?.SocialMedia?.filter(
        socialMedia => !!socialMedia.socialMedia.socialMediaType
      ) || []
    );
  }

  get mediaList() {
    return this.event?.media || [];
  }

  get youtubeVideoUrl() {
    return this.mediaList.find(
      media => media.mediaFile.fileType?.toLowerCase() === 'youtube video'
    )?.mediaFile.location;
  }

  get ctaButtonText() {
    return !this.toggleAccordian
      ? this.isGenericEvent
        ? 'More Info'
        : 'Purchase'
      : 'Close';
  }

  get orderEmpty() {
    return this.onlineConfigurations.length === 0;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    if (!this.isGenericEvent) {
      this.tickets =
        this.event?.Tickets &&
        this.event?.Tickets[0]?.Configurations.map((element: any) => {
          return {
            ...element?.TicketConfigurations,
            date: element.TicketConfigurations.updatedAt,
            ticketQty: element?.TicketConfigurations?.ticketQty
              ? element?.TicketConfigurations?.ticketQty
              : 0
          };
        });

      if (this.tickets.length > 1) {
        const ticketPrices = this.onlineConfigurations.filter(
          e => dollarFigureToNumber(e?.ticketPrice) !== 0
        );
        if (ticketPrices.length === 1) {
          this.ticketMinValue = ticketPrices[0]?.ticketPrice?.replace('$', '');
        } else {
          this.ticketMinValue = ticketPrices[0]?.ticketPrice?.replace('$', '');

          this.ticketMaxValue = ticketPrices[
            ticketPrices.length - 1
          ]?.ticketPrice?.replace('$', '');
        }
        if (this.ticketMinValue === Infinity) {
          this.ticketMinValue = 0;
        }
      } else {
        this.ticketMinValue = this.tickets[0]?.ticketPrice?.replace('$', '');
      }

      this.tickets.forEach((config, idx) => {
        this.feesService
          .getPlatformFee(
            this.feesService.dollarFigureToNumber(config.ticketPrice),
            {
              companyId: this.event.venue?.companyId,
              venueId: this.event.venue?.venueId,
              venueFee: this.feesService.dollarFigureToNumber(config.venueFee)
            }
          )
          .then(platformFee => {
            this.tickets[idx].platformFee =
              this.feesService.formatAsDollarFigure(platformFee);
          });

        this.feesService
          .calculateProcessingFeeForAmount(
            this.dollarFigureToNumber(config.ticketPrice) +
              this.dollarFigureToNumber(config.platformFee) +
              this.dollarFigureToNumber(config.venueFee),
            {
              companyId: this.event.venue?.companyId,
              venueId: this.event.venue?.venueId
            }
          )
          .then(processingFee => {
            this.tickets[idx].processingFee =
              this.feesService.formatAsDollarFigure(processingFee);
          });
      });
    }
    this.comments.disable();
    if (
      !this.event.passcode &&
      (this.route.snapshot.queryParams['selectedEvent'] ===
        this.event.eventInstanceId ||
        this.route.snapshot.queryParams['searchEvent'] ===
          this.event.eventInstanceId)
    ) {
      this.toggle();
    } else {
      this.toggleAccordian = false;
    }

    this.checkEventIsSoldOut();

    this.orderStateManager.clearPersistedOrder();
  }

  override ngOnDestroy(): void {
    this.sub?.unsubscribe();

    super.ngOnDestroy();
  }

  override onOrderAvailabilityUpdate(): void {
    this.checkEventIsSoldOut(true);
  }

  async initializeOrderSelection() {
    if (this.isGenericEvent) return;

    this.orderStateManager.setOrderItems(
      this.tickets.map(ticket => {
        let pool: QuantityPool | undefined = undefined;

        if (ticket.parentTicketId) {
          const poolTickets = this.tickets.filter(
            t =>
              ticket.parentTicketId === t.parentTicketId ||
              ticket.parentTicketId === t.ticketConfigId
          );
          const poolChildren = poolTickets.filter(t => !!t.parentTicketId);
          const poolParent = this.tickets.find(
            t => t.ticketConfigId === ticket.parentTicketId
          );

          pool = {
            id: poolParent?.ticketConfigId,
            quantityOnHold: 0,
            quantitySold: poolTickets.reduce(
              (total, t) => total + (t.ticketConfigTotals?.ticketsSold ?? 0),
              0
            ),
            childrenItemIds: poolChildren.map(t => t.ticketConfigId),
            quantity: poolParent?.noOfSeats ?? 0
          };
        } else {
          const poolTickets = this.tickets.filter(
            t =>
              ticket.ticketConfigId === t.parentTicketId ||
              ticket.ticketConfigId === t.ticketConfigId
          );
          const poolChildren = poolTickets.filter(t => !!t.parentTicketId);
          const poolParent = ticket;

          pool =
            poolChildren.length === 0
              ? undefined
              : {
                  id: poolParent.ticketConfigId,
                  quantityOnHold: 0,
                  quantitySold: poolTickets.reduce(
                    (total, t) =>
                      total + (t.ticketConfigTotals?.ticketsSold ?? 0),
                    0
                  ),
                  childrenItemIds: poolChildren.map(t => t.ticketConfigId),
                  quantity: poolParent?.noOfSeats ?? 0
                };
        }

        return {
          id: ticket.ticketConfigId,
          isPoolParent: !!pool && !ticket.parentTicketId,
          quantitySold: ticket.ticketConfigTotals?.ticketsSold ?? 0,
          totalQuantity: ticket.noOfSeats ?? 0,
          type: 'ticket',
          minPerOrder: ticket.perOrderMin,
          maxPerOrder: ticket.perOrderMax,

          startSellingDate: ticket.startSellingDate,
          stopSellingDate: ticket.stopSellingDate,
          startSellingTime: ticket.startSellingTime,
          stopSellingTime: ticket.stopSellingTime,
          pool
        };
      })
    );

    this.orderSelectionInitialized = true;
    await this.orderStateManager.fetchHoldInformation();
    this.orderStateManager.updateAvailability();

    this.changeDetector.detectChanges();
  }

  checkEventIsSoldOut(checkTicketHold = false) {
    const onlineQuantity = this.tickets
      .filter(
        config =>
          ['online', 'both', ''].includes(
            config.salesChannel?.toLowerCase() ?? ''
          ) && !config.parentTicketId
      )
      .reduce((total, config) => total + (config.noOfSeats ?? 0), 0);

    const onlineTicketCounts = this.event.ticketCount.filter(ticket => {
      if (ticket.parentTicketConfigId) {
        if (
          this.event.ticketCount
            .find(t => t.ticketConfigId === ticket.parentTicketConfigId)
            ?.salesChannel?.toLowerCase() === 'boxoffice'
        )
          return false;
        return true;
      }

      if (ticket.salesChannel?.toLowerCase() === 'boxoffice') return false;
      return true;
    });
    const ticketsSold = onlineTicketCounts.reduce(
      (total, el) => total + (el.ticketCount ?? 0),
      0
    );

    this.showIsSoldOut = onlineQuantity <= ticketsSold && !this.isGenericEvent;

    if (!this.showIsSoldOut && checkTicketHold) {
      this.showIsSoldOut = this.onlineConfigurations.every(config =>
        this.itemIsSoldOut(config.ticketConfigId)
      );

      this.changeDetector.detectChanges();
    }
  }

  verifyDiscountGroup() {
    switch (this.appliedDiscount?.groupDiscountType) {
      case 'A Minimum Order Of':
        if (this.totalTickets < this.appliedDiscount?.groupDiscountAmount) {
          this.discountError = `This discount code is not applicable for orders of less than ${
            this.appliedDiscount?.groupDiscountAmount
          } ${
            this.appliedDiscount?.groupDiscountAmount === 1
              ? ' ticket'
              : ' tickets'
          }.`;
          this.appliedDiscount = undefined;
        }
        break;
      case 'A Maximum Order Of':
        if (this.totalTickets > this.appliedDiscount?.groupDiscountAmount) {
          this.discountError = `This discount code is not applicable for orders of more than ${
            this.appliedDiscount?.groupDiscountAmount
          } ${
            this.appliedDiscount?.groupDiscountAmount === 1
              ? ' ticket'
              : ' tickets'
          }.`;
          this.appliedDiscount = undefined;
        }
        break;
      case 'An Order of Exactly':
        if (this.totalTickets !== this.appliedDiscount?.groupDiscountAmount) {
          this.discountError = `The discount code must be used for exactly ${
            this.appliedDiscount?.groupDiscountAmount
          } ${
            this.appliedDiscount?.groupDiscountAmount === 1
              ? ' ticket'
              : ' tickets'
          }.`;
          this.appliedDiscount = undefined;
        }
        break;
      default:
        this.discountError = '';
        break;
    }
  }
  ngOnChanges(changes: SimpleChanges): void {}

  async toggle() {
    const eventHasPasscode = !!this.event.passcode;

    if (eventHasPasscode && !this.passcodeVerified) {
      const correctPasscode = await this.askForPasscode();
      if (correctPasscode) {
        this.passcodeVerified = true;
      } else {
        return;
      }
    }

    this.toggleAccordian = !this.toggleAccordian;

    if (this.toggleAccordian) {
      if (!this.orderSelectionInitialized) {
        await this.initializeOrderSelection();
      }
      this.startTrackingAvailability();
    } else {
      this.stopTrackingAvailability();
    }

    this.router.navigate([], {
      queryParams: this.toggleAccordian
        ? { selectedEvent: this.getEventId() }
        : { selectedEvent: null },
      queryParamsHandling: 'merge'
    });
  }

  formatTime(time: string) {
    return moment(time, 'HH:mm:ss').format('hh:mm A');
  }

  dollarFigureToNumber(dollarFigure?: string) {
    if (!dollarFigure) return 0;
    return Number(dollarFigure.replace(/[^0-9.-]+/g, ''));
  }

  getTotal(price1: string, price2: string, price3: string) {
    return (
      dollarFigureToNumber(price1) +
      dollarFigureToNumber(price2) +
      dollarFigureToNumber(price3)
    );
  }

  formattedVenueAddress(): string {
    return [
      this.venueAddress?.address?.sameAsCompany
        ? (this.companyAddress?.address?.streetAddress,
          this.companyAddress?.address?.city,
          `${this.companyAddress?.address?.stateProvince ?? ''} ${
            this.companyAddress?.address?.postalCode ?? ''
          }`.trim())
        : `${this.venueAddress?.address?.streetAddress ?? ''} , ${
            this.venueAddress?.address?.city ?? ''
          } , ${this.venueAddress?.address?.stateProvince ?? ''} ${
            this.venueAddress?.address?.postalCode ?? ''
          }`.trim()
    ]
      .filter(v => !!v)
      .join(', ');
  }

  override itemIncrementClick(itemId: string) {
    this.isDisable = false;
    this.errorMessage = '';

    super.itemIncrementClick(itemId);

    this.verifyDiscountGroup();
  }

  override itemDecrementClick(itemId: string) {
    super.itemDecrementClick(itemId);
    this.isDisable = false;
    this.errorMessage = '';

    this.verifyDiscountGroup();
  }

  onApplyDiscount(discountInfo: TixGetDiscountInfoQuery['Discount'][0]) {
    this.appliedDiscount = discountInfo;

    this.verifyDiscountGroup();
  }

  askForPasscode(): Promise<boolean> {
    return new Promise(resolve => {
      const dialog = this.dialog.open(TixCheckEventPasscodeComponent);
      dialog.componentInstance.passcode = this.event.passcode;

      dialog.componentInstance.eventPasscodeVerified.subscribe(valid => {
        resolve(valid);
        dialog.close();
      });

      dialog.afterClosed().subscribe(() => resolve(false));
    });
  }
}
