import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserSelector } from '@tix/auth/state';
import { AmplitudeService } from '@tix/core/analytics';
import { addLog } from 'libs/layout/src/lib/state/logs.actions';
import * as LogRocket from 'logrocket';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Component({
  selector: 'tix-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store,
    private router: Router,
    private amplitudeService: AmplitudeService
  ) {}

  sub?: Subscription;

  overrideConsoleMethods() {
    const dispatchLog = (
      type: 'INFO' | 'LOG' | 'WARN' | 'ERROR',
      message: [any?, ...any[]]
    ) => {
      this.store.dispatch(
        addLog({
          log: {
            type,
            message,
            timestamp: new Date()
          }
        })
      );
    };

    const originalInfo = console.info;
    console.info = function (...args) {
      dispatchLog('INFO', args);
      originalInfo.apply(console, args);
    };

    const originalLog = console.log;
    console.log = function (...args) {
      dispatchLog('LOG', args);
      originalLog.apply(console, args);
    };

    const originalError = console.error;
    console.error = function (...args) {
      dispatchLog('ERROR', args);
      originalError.apply(console, args);
    };

    const originalWarn = console.warn;
    console.warn = function (...args) {
      dispatchLog('WARN', args);
      originalWarn.apply(console, args);
    };

    /*  window.addEventListener('unhandledrejection', event => {
      dispatchLog('ERROR', event.reason?.message || event.reason);
    }); */
  }

  ngOnInit(): void {
    if (!environment.disableLoggingOverride) {
      this.overrideConsoleMethods();
    }

    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        const pageName = this.getPageName(event.urlAfterRedirects);
        this.amplitudeService.trackEvent(`Page Visit: ${pageName}`);
      });

    this.sub = this.store.select(UserSelector.getAuthenticatedUser).subscribe({
      next: user => {
        if (!user) return;

        LogRocket.identify(user.uid, {
          name: user.displayName,
          email: user.email
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  getPageName(url: string): string {
    const _url = url.split('/').filter(Boolean);
    const length = _url.length;

    const baseNames: { [key: string]: string } = {
      '/company': 'Companies List Page',
      company: 'Company Overview Page',
      venues: length === 4 ? 'Venue Info Page' : 'Venues List Page',
      events: length === 4 ? 'Events Info Page' : 'Events List Page',
      staff: length === 4 ? 'Staff Info Page' : 'Staff List Page',
      discounts: length === 4 ? 'Discounts Info Page' : 'Discounts List Page',
      'Add-Ons': length === 5 ? 'Add-Ons Info Page' : 'Add-Ons List Page'
    };

    if (length === 1 && _url[0] === 'company') return baseNames['/company'];
    if (length === 2) {
      const params = new URLSearchParams(_url[1].split('?')[1]);
      if (params.has('selectedEvent'))
        return 'Front Office Selected Event List Page';
      if (_url[0] === 'venue') return 'Front Office Events List Page';
      if (_url[0] === 'checkout') return 'Checkout Page';
      if (_url[1] === '1') return 'Edit Account Info Page';
      if (_url[1] === '2') return 'Edit Account Phone Number Page';
      if (_url[1] === '3') return 'Edit Account Address Page';
      if (_url[2] === 'customers') return 'Customers Page';
      if (_url[1] === 'add') return 'Create New Company Page';
      if (_url[0] === 'company') return baseNames['company'];
    }

    if (length === 3) {
      if (_url[0] === 'success') return 'Success Page';
      if (_url[2] === 'communications') return 'Communications Page';
      if (_url[2] === 'reports') return 'Reports Page';
      if (baseNames[_url[2]]) return baseNames[_url[2]];
    }

    if (length === 4) {
      if (_url[2] === 'dashboard') return 'Dashboard Page';
      if (_url[2] === 'addons') return 'FrontOffice Addons';
      if (_url[3] === 'add') {
        if (_url[2] === 'venues') return 'Add New Venue Page';
        if (_url[2] === 'events') return 'Add New Event Page';
        if (_url[2] === 'staff') return 'Add New Staff Page';
        if (_url[2] === 'discounts') return 'Add New Discount Page';
        if (_url[2] === 'communications') return 'Add New Communications Page';
      }
      if (_url[2] === 'Add-Ons' && _url[3] === 'new')
        return 'Add New Add-On Page';
      if (_url[2] === 'reports') {
        if (_url[3] === 'company-summary') return 'Company Summary Report Page';
        if (_url[3] === 'event-summary') return 'Event Reservation Report Page';
        if (_url[3] === 'event-details') return 'Event Tickets Report Page';
        if (_url[3] === 'upcoming-events-summary')
          return 'Upcoming Events Summary Report Page';
        if (_url[3] === 'event-nightly-closeout')
          return 'Nightly Closeout Report Page';
        if (_url[3] === 'tickets-by-name') return 'Tickets By Name Report Page';
        if (_url[3] === 'daily-audit-report') return 'Daily Audit Report Page';
        if (_url[3] === 'discount-code-report')
          return 'Discount Code Report Page';
      }
      if (baseNames[_url[2]]) return baseNames[_url[2]];
    }

    if (length === 5 && _url[2] === 'Add-Ons') return baseNames['Add-Ons'];

    return `${_url[length - 1]} Page`;
  }
}
