import { createAction, props } from '@ngrx/store';
import { PaymentIntent } from '@stripe/stripe-js';
import {
  InputMaybe,
  Maybe,
  TixBuyerProductFragmentFragment,
  TixPatronFIlteredEventsList,
  TixPatronFIlteredEventsListAggregateFields,
  TixPayment,
  TixPaymentInsertInput,
  TixVenue,
  TixVenueTicket
} from '@tix/data-access';
import { CheckoutOrderInfo, PortalEventList } from '@tix/event-buyer/services';

export type AmountInfo = {
  venueFee: string;
  platformFee: string;
  totalProcessingFee: number;
  donationProcessingFee: number;
  orderItemsProcessingFee: number;
  totalTax: number;
  discountId?: string;
  discountAmount: string;
  totalAmountPaid: number;
  totalSubtotal: number;
  orderItemsSubtotal: number;
};

export const getVenueInfo = createAction(
  '[ GET VENUE] Get Venue Info',
  props<{ venueId: string }>()
);

export const getVenueInfoSuccess = createAction(
  '[GET VENUE] Get Venue Info Success',
  props<{ VenueInfo: TixVenue }>()
);

export const getVenueInfoFailure = createAction(
  '[GET VENUE] Get Venue Info Failure',
  props<{ error: any }>()
);

export const insertPaymentReservationTicket = createAction(
  '[EventBuyer API] Add Payment reservation ticket',
  props<{
    logToHive: boolean;
    paymentIntent?: PaymentIntent;
    paymentReservationObj: InputMaybe<TixPaymentInsertInput>;
    venueId: string;
    orderData: CheckoutOrderInfo;
    reload?: boolean;
    amountInfo: AmountInfo;
    form?: any;
    checkCustomerEmail?: any;
    products: (TixBuyerProductFragmentFragment & { selected: number })[];
  }>()
);

export const insertPaymentReservationTicketSuccess = createAction(
  '[EventBuyer API Success] Add Payment reservation ticket success',
  props<{ paymentObj: TixPayment }>()
);
export const insertPaymentReservationTicketFailure = createAction(
  '[EventBuyer API Failure] Add Payment reservation ticket failure',
  props<{ error: any }>()
);

export const AddQuantityTicket = createAction(
  '[EventBuyer API] Add Quantity of Event Ticket',
  props<{ orderItems: any }>()
);

export const DeleteItemFromCart = createAction(
  '[EventBuyer API] Remove Quantity of Event Ticket',
  props<{ orderItems: any }>()
);

export const AddQuantityTicketSuccess = createAction(
  '[EventBuyer API] Add Quantity of ticket Success',
  props<{ venueTicket: TixVenueTicket }>()
);

export const saveOrderItems = createAction(
  '[EventBuyer API] Save order Lists',
  props<{
    orderItems: Maybe<PortalEventList>;
  }>()
);

export const AddQuantityTicketFailure = createAction(
  '[EventBuyer API] Add Quantity of ticket Failure',
  props<{ error: any }>()
);
