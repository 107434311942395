import { Injectable } from '@angular/core';
import { CheckoutOrderInfo } from './portal-checkout.service';
import {
  TixContactObjRelInsertInput,
  TixReservationPayment,
  TixTicket,
  TixTicketReservationDetailInsertInput
} from '@tix/data-access';
import { OrderTicket } from './portal-checkout.service';
import { dollarFigureToNumber } from '@tix/shared/utils';

import { Order, Calculator } from 'asylumtix-calculator';

@Injectable({
  providedIn: 'root'
})
export class ReservationDetailsService {
  constructor() {}

  createReservationDetailsRows({
    order,
    calculator,
    ticketConfigMap,
    donationComment,
    orderInfo,
    contactObj,
    customerId
  }: {
    order: Order;
    calculator: Calculator;

    // Map each ticket config id to the list of ticket ids in the order
    ticketConfigMap: Record<string, string[]>;

    donationComment?: string;

    orderInfo: CheckoutOrderInfo | undefined;
    contactObj: TixContactObjRelInsertInput;
    customerId: string;
  }): TixTicketReservationDetailInsertInput[] {
    // The ticket configuration that have a quantity selected greater than 0
    const selectedTicketConfigs = Object.keys(ticketConfigMap).filter(
      ticketConfigId => ticketConfigMap[ticketConfigId]?.length
    );
    const paidQuantityPerTicketConfigMap: Record<string, number> = {};

    for (const selectedTicketConfig of selectedTicketConfigs) {
      paidQuantityPerTicketConfigMap[selectedTicketConfig] = ticketConfigMap[
        selectedTicketConfig
      ].filter(ticketId => {
        const ticket = order.tickets.find(t => t.id === ticketId)!;
        return !!calculator.getAmountPaidForTicket(order, ticket);
      }).length;
    }

    const totalPaidQuantity = selectedTicketConfigs.reduce(
      (total, ticketConfigId) => {
        return total + paidQuantityPerTicketConfigMap[ticketConfigId];
      },
      0
    );

    const totalFreeQuantity = selectedTicketConfigs.reduce(
      (total, ticketConfigId) => {
        const ticketConfig = orderInfo?.tickets.find(
          t => t.ticketConfigId === ticketConfigId
        );
        const isFree = dollarFigureToNumber(ticketConfig?.ticketPrice) === 0;

        if (isFree) {
          return total + ticketConfigMap[ticketConfigId].length;
        }

        return total;
      },
      0
    );

    const totalQuantity = selectedTicketConfigs.reduce(
      (total, ticketConfigId) =>
        total + ticketConfigMap[ticketConfigId]!.length,
      0
    );

    const details: TixTicketReservationDetailInsertInput[] = [
      this.generatePlatformFeeRow({
        totalPlatformFee: calculator.getPlatformFee(order),
        paidQuantity: totalPaidQuantity,
        orderInfo,
        contactObj,
        customerId
      }),
      this.generateVenueFeeRow({
        totalVenueFee: calculator.getVenueFee(order),
        ticketsWithVenueFeeApplied: totalPaidQuantity,
        orderInfo,
        contactObj,
        customerId
      }),
      this.generateProcessingFeeRow({
        ticketTotalProcessingFee:
          calculator.getTotalTicketsProcessingFee(order),
        orderInfo,
        contactObj,
        customerId
      })
    ];

    if (order.donation?.amount) {
      details.push(
        this.generateDonationRow({
          donationAmount: order.donation.amount,
          donationComment: donationComment ?? '',
          orderInfo,
          contactObj,
          customerId
        })
      );

      details.push(
        this.generateDonationProcessingFeeRow({
          donationProcessingFeeAmount:
            calculator.getAmountPaidForDonation(order) -
            (order.donation?.amount ?? 0),
          donationComment: donationComment ?? '',
          orderInfo,
          contactObj,
          customerId
        })
      );
    }

    if (orderInfo?.discount?.appliedTo?.toLowerCase() === 'reservation') {
      const discountRow = this.generateDiscountRow({
        totalDiscount: calculator.getTotalDiscountAmount(order),
        discountedQuantity: 1,
        orderInfo,
        customerId,
        contactObj
      });

      if (discountRow) details.push(discountRow);
    }

    for (const ticketConfigId of selectedTicketConfigs) {
      const paidQuantity = paidQuantityPerTicketConfigMap[ticketConfigId]!;
      const tickets = order.tickets.filter(t =>
        ticketConfigMap[ticketConfigId]!.includes(t.id)
      );

      if (orderInfo?.discount?.appliedTo?.toLowerCase() === 'ticket') {
        const discountedTickets = tickets.filter(
          ticket => calculator.getTicketDiscountAmount(order, ticket) > 0
        );

        const discountRow = this.generateDiscountRowForTicketConfigId({
          discountAmountForConfig: discountedTickets.reduce(
            (total, ticket) =>
              total + calculator.getTicketDiscountAmount(order, ticket),
            0
          ),
          discountedQuantity: discountedTickets.length,
          ticketConfigId,
          orderInfo,
          customerId,
          contactObj
        });

        if (discountRow) details.push(discountRow);
      }

      details.push(
        this.generateVenueFeeRowForTicketConfigId({
          ticketConfigId,
          paidQuantity,
          amount: tickets.reduce(
            (total, ticket) =>
              total + calculator.getTicketVenueFee(order, ticket),
            0
          ),
          orderInfo,
          contactObj,
          customerId
        })
      );
      details.push(
        this.generatePlatformFeeRowForTicketConfigId({
          ticketConfigId,
          paidQuantity,
          amount: tickets.reduce(
            (total, ticket) =>
              total + calculator.getTicketPlatformFee(order, ticket),
            0
          ),
          orderInfo,
          contactObj,
          customerId
        })
      );

      details.push(
        this.generateTicketTotalRowForTicketConfigId({
          ticketConfigId,
          paidQuantity,
          amount: tickets.reduce(
            (total, ticket) =>
              total + calculator.getRawPriceForTicket(order, ticket),
            0
          ),
          orderInfo,
          contactObj,
          customerId
        })
      );

      details.push(
        this.generateProcessingFeeRowForTicketConfigId({
          ticketConfigId,
          paidQuantity,
          amount: tickets.reduce(
            (total, ticket) =>
              total + calculator.getTicketProcessingFee(order, ticket),
            0
          ),
          orderInfo,
          contactObj,
          customerId
        })
      );
    }

    return details;
  }

  generateDiscountRowForTicketConfigId({
    discountAmountForConfig,
    ticketConfigId,
    discountedQuantity,
    orderInfo,
    contactObj,
    customerId
  }: {
    discountAmountForConfig: number;
    ticketConfigId: string;
    discountedQuantity: number;
    orderInfo: CheckoutOrderInfo | undefined;
    contactObj: TixContactObjRelInsertInput;
    customerId: string;
  }) {
    if (discountAmountForConfig === 0) return null;

    return {
      amount: -discountAmountForConfig,
      quantity: discountedQuantity,
      price: -(discountAmountForConfig / discountedQuantity),
      eventInstanceId: orderInfo?.eventInstanceId,
      ticketConfigId,
      contact: contactObj,
      createdBy: customerId,
      type: 'discount'
    };
  }

  generateDiscountRow({
    totalDiscount,
    discountedQuantity,
    orderInfo,
    contactObj,
    customerId
  }: {
    totalDiscount: number;
    discountedQuantity: number;
    orderInfo: CheckoutOrderInfo | undefined;
    contactObj: TixContactObjRelInsertInput;
    customerId: string;
  }) {
    if (totalDiscount === 0) return null;

    return {
      amount: -totalDiscount,
      quantity: discountedQuantity,
      price: -(totalDiscount / discountedQuantity),
      eventInstanceId: orderInfo?.eventInstanceId,
      contact: contactObj,
      createdBy: customerId,
      type: 'discount'
    };
  }

  generateVenueFeeRow({
    totalVenueFee,
    ticketsWithVenueFeeApplied,
    orderInfo,
    contactObj,
    customerId
  }: {
    totalVenueFee: number;
    ticketsWithVenueFeeApplied: number;
    orderInfo: CheckoutOrderInfo | undefined;
    contactObj: TixContactObjRelInsertInput;
    customerId: string;
  }) {
    if (ticketsWithVenueFeeApplied === 0 || totalVenueFee === 0)
      return {
        amount: 0,
        quantity: 0,
        price: 0,
        eventInstanceId: orderInfo?.eventInstanceId,
        contact: contactObj,
        createdBy: customerId,
        type: 'venue fee total'
      };

    return {
      amount: totalVenueFee,
      quantity: ticketsWithVenueFeeApplied,
      price: totalVenueFee / ticketsWithVenueFeeApplied,
      eventInstanceId: orderInfo?.eventInstanceId,
      contact: contactObj,
      createdBy: customerId,
      type: 'venue fee total'
    };
  }

  generatePlatformFeeRow({
    totalPlatformFee,
    paidQuantity,
    orderInfo,
    contactObj,
    customerId
  }: {
    totalPlatformFee: number;
    paidQuantity: number;
    orderInfo: CheckoutOrderInfo | undefined;
    contactObj: TixContactObjRelInsertInput;
    customerId: string;
  }) {
    return {
      amount: totalPlatformFee,
      quantity: paidQuantity,
      price: totalPlatformFee ? totalPlatformFee / paidQuantity : 0,
      eventInstanceId: orderInfo?.eventInstanceId,
      contact: contactObj,
      createdBy: customerId,
      type: 'platform fee total'
    };
  }

  generateProcessingFeeRow({
    ticketTotalProcessingFee,
    orderInfo,
    contactObj,
    customerId
  }: {
    ticketTotalProcessingFee: number;
    orderInfo: CheckoutOrderInfo | undefined;
    contactObj: TixContactObjRelInsertInput;
    customerId: string;
  }) {
    return {
      amount: ticketTotalProcessingFee,
      quantity: 1,
      price: ticketTotalProcessingFee,
      eventInstanceId: orderInfo?.eventInstanceId,
      contact: contactObj,
      createdBy: customerId,
      type: 'processing fee total'
    };
  }

  generateDonationRow({
    donationAmount,
    donationComment,
    orderInfo,
    contactObj,
    customerId
  }: {
    donationAmount: number;
    donationComment: string;
    orderInfo: CheckoutOrderInfo | undefined;
    contactObj: TixContactObjRelInsertInput;
    customerId: string;
  }) {
    return {
      amount: donationAmount,
      quantity: 1,
      price: donationAmount,
      eventInstanceId: orderInfo?.eventInstanceId,
      contact: contactObj,
      createdBy: customerId,
      type: 'donation',
      comment: donationComment || 'charity'
    };
  }

  generateDonationProcessingFeeRow({
    donationProcessingFeeAmount,
    donationComment,
    orderInfo,
    contactObj,
    customerId
  }: {
    donationProcessingFeeAmount: number;
    donationComment: string;
    orderInfo: CheckoutOrderInfo | undefined;
    contactObj: TixContactObjRelInsertInput;
    customerId: string;
  }) {
    return {
      amount: donationProcessingFeeAmount,
      quantity: 1,
      price: donationProcessingFeeAmount,
      eventInstanceId: orderInfo?.eventInstanceId,
      contact: contactObj,
      createdBy: customerId,
      type: 'donation processing fee',
      comment: donationComment || 'charity'
    };
  }

  generateVenueFeeRowForTicketConfigId({
    ticketConfigId,
    amount,
    paidQuantity,
    orderInfo,
    contactObj,
    customerId
  }: {
    ticketConfigId: string;
    amount: number;
    paidQuantity: number;
    orderInfo: CheckoutOrderInfo | undefined;
    contactObj: TixContactObjRelInsertInput;
    customerId: string;
  }) {
    if (amount == 0) {
      return {
        amount: 0,
        quantity: paidQuantity,
        price: 0,
        eventInstanceId: orderInfo?.eventInstanceId,
        contact: contactObj,
        createdBy: customerId,
        ticketConfigId: ticketConfigId,
        type: 'venue fee'
      };
    }
    return {
      amount: amount,
      quantity: paidQuantity,
      price: amount / paidQuantity,
      eventInstanceId: orderInfo?.eventInstanceId,
      contact: contactObj,
      createdBy: customerId,
      ticketConfigId: ticketConfigId,
      type: 'venue fee'
    };
  }

  generatePlatformFeeRowForTicketConfigId({
    ticketConfigId,
    amount,
    paidQuantity,
    orderInfo,
    contactObj,
    customerId
  }: {
    ticketConfigId: string;
    amount: number;
    paidQuantity: number;
    orderInfo: CheckoutOrderInfo | undefined;
    contactObj: TixContactObjRelInsertInput;
    customerId: string;
  }) {
    if (amount == 0) {
      return {
        amount: 0,
        quantity: paidQuantity,
        price: 0,
        eventInstanceId: orderInfo?.eventInstanceId,
        contact: contactObj,
        createdBy: customerId,
        ticketConfigId: ticketConfigId,
        type: 'platform fee'
      };
    }
    return {
      amount,
      quantity: paidQuantity,
      price: amount / paidQuantity,
      eventInstanceId: orderInfo?.eventInstanceId,
      contact: contactObj,
      createdBy: customerId,
      ticketConfigId: ticketConfigId,
      type: 'platform fee'
    };
  }

  generateProcessingFeeRowForTicketConfigId({
    ticketConfigId,
    amount,
    paidQuantity,
    orderInfo,
    contactObj,
    customerId
  }: {
    ticketConfigId: string;
    amount: number;
    paidQuantity: number;
    orderInfo: CheckoutOrderInfo | undefined;
    contactObj: TixContactObjRelInsertInput;
    customerId: string;
  }) {
    if (amount == 0) {
      return {
        amount: 0,
        quantity: paidQuantity,
        price: 0,
        eventInstanceId: orderInfo?.eventInstanceId,
        contact: contactObj,
        createdBy: customerId,
        ticketConfigId: ticketConfigId,
        type: 'processing fee'
      };
    }
    return {
      amount,
      quantity: paidQuantity,
      price: amount / paidQuantity,
      eventInstanceId: orderInfo?.eventInstanceId,
      contact: contactObj,
      createdBy: customerId,
      ticketConfigId: ticketConfigId,
      type: 'processing fee'
    };
  }

  generateTicketTotalRowForTicketConfigId({
    ticketConfigId,
    amount,
    paidQuantity,
    orderInfo,
    contactObj,
    customerId
  }: {
    ticketConfigId: string;
    amount: number;
    paidQuantity: number;
    orderInfo: CheckoutOrderInfo | undefined;
    contactObj: TixContactObjRelInsertInput;
    customerId: string;
  }) {
    if (amount == 0) {
      return {
        amount: 0,
        quantity: paidQuantity,
        price: 0,
        eventInstanceId: orderInfo?.eventInstanceId,
        contact: contactObj,
        createdBy: customerId,
        ticketConfigId: ticketConfigId,
        type: 'ticket total'
      };
    }
    return {
      amount,
      quantity: paidQuantity,
      price: amount / paidQuantity,
      eventInstanceId: orderInfo?.eventInstanceId,
      contact: contactObj,
      createdBy: customerId,
      ticketConfigId: ticketConfigId,
      type: 'ticket total'
    };
  }
}
