import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import {
  HeroPencil,
  HeroPlusSolid,
  HeroTrash,
  HeroUsersSolid,
  TixButtonModule,
  TixCardModule,
  TixIconModule,
  TixIconRegistry,
  TixInputModule,
  BtnLoadingDirectiveModule,
  HeroClipboard,
  HeroCheck,
  TixCheckboxModule
} from '@tix/shared/ui/components';
import { TextMaskModule } from 'angular2-text-mask';
import { TixCommunicationsAdminModule } from '@tix/shared/ui/components';
import { TicketConfigurationModule } from './ticket-configuration/ticket-configuration.module';
import { TixVenueCardComponent } from './venue-card/venue-card.component';
import { TixVenueInfoComponent } from './venue-profile/venue-profile.component';
import { HttpClientModule } from '@angular/common/http';
import { QuillModule } from 'ngx-quill';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TixVenueAdditionalConfigurationComponent } from './venue-additional-configuration/venue-additional-configuration.component';
import { TixVenueCommunicationsComponent } from './venue-communications/venue-communications.component';
import { FormGeneralAdditionnalConfigComponent } from './form-general-additionnal-config/form-general-additionnal-config.component';
import { TixVenueCustomerSupportComponent } from './venue-customer-support/venue-customer-support.component';
import { TixCustomerSupportHelpTextPopUpComponent } from './customer-support-help-text-pop-up/customer-support-help-text-pop-up.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TixIconModule,
    TixButtonModule,
    MatButtonModule,
    CdkAccordionModule,
    TixCardModule,
    TixCheckboxModule,
    TicketConfigurationModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    TextMaskModule,
    TixInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatSnackBarModule,
    BtnLoadingDirectiveModule,
    HttpClientModule,
    QuillModule,
    TixCommunicationsAdminModule,
    TixCardModule,
    MatNativeDateModule

    // AngularEditorModule
  ],
  declarations: [
    TixVenueCardComponent,
    TixVenueInfoComponent,
    TixVenueAdditionalConfigurationComponent,
    TixVenueCommunicationsComponent,
    FormGeneralAdditionnalConfigComponent,
    TixVenueCustomerSupportComponent,
    TixCustomerSupportHelpTextPopUpComponent
  ],
  exports: [
    TixVenueCardComponent,
    TicketConfigurationModule,
    TixVenueInfoComponent,
    TixVenueAdditionalConfigurationComponent,
    FormGeneralAdditionnalConfigComponent,
    TixVenueCommunicationsComponent,
    TixVenueCustomerSupportComponent,
    TixCustomerSupportHelpTextPopUpComponent
  ]
})
export class TixVenuesComponentsModule {
  constructor(iconRegistry: TixIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIconConfigs([
      HeroPlusSolid,
      HeroCheck,
      HeroUsersSolid,
      HeroTrash,
      HeroPencil,
      HeroClipboard
    ]);
    iconRegistry.addSvgIcon(
      'open-external',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/open-external.svg'
      )
    );
  }
}
