import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  TixConfigurationItem,
  TixConfigurationItemsService
} from '@tix/shared/state';
import { Subscription } from 'rxjs';

import { TixFullVenue, TixVenuesService } from '@tix/venues/state';

type InputControl = {
  [key: string]: FormControl;
};

type InputValue = {
  [key: string]: string | boolean | undefined;
};

@Component({
  selector: 'tix-venue-additional-configuration',
  templateUrl: './venue-additional-configuration.component.html',
  styleUrls: ['./venue-additional-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TixVenueAdditionalConfigurationComponent implements OnInit {
  @Input() venue: TixFullVenue;

  configFormChild: FormGroup;
  receiveConfigFormFromChild(data: FormGroup) {
    this.configFormChild = data;

    if (!this.editPermission) {
      this.configFormChild.disable();
    }
  }

  @Input()
  editPermission = true;

  castBoolToString(s: string | boolean | undefined | null) {
    if (s === 'false' || s === 'False') {
      return false;
    }

    return true;
  }

  subHeaders: string[] = [];
  allInputsControls: InputControl;
  allInputValues: InputValue = {};
  configurationForm = new FormGroup({});
  sub: Subscription;

  sortedData: TixConfigurationItem[];

  isSaving = false;

  constructor(
    private configItemsService: TixConfigurationItemsService,
    private venueService: TixVenuesService,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getConfigValue();
    this.cdr.detectChanges();
    // get validator by type text,percent,money
    if (!this.editPermission) this.configFormChild.disable();
  }

  getConfigValue() {
    this.venue.configurationValues.map(config => {
      if (config != undefined) {
        this.allInputValues = {
          ...this.allInputValues,
          [config?.Value?.configurationItemId]: config.Value?.value
        };
      }
    });
  }

  async onSubmit() {
    if (this.configFormChild.invalid) {
      return;
    }
    this.isSaving = true;

    try {
      const updatePromises = Object.keys(this.configFormChild.controls).map(
        itemId =>
          this.configItemsService
            .updateConfigValueForVenue(
              this.venue.venueId,
              itemId,
              this.configFormChild?.get(itemId)?.value.toString()
            )
            .toPromise()
      );

      await Promise.all(updatePromises);

      //this.receivedData.markAsPristine();
      this.snackBar.open('Your changes have been saved successfully!');

      this.venueService.clearCacheForVenue(this.venue.venueId);
    } catch (e) {
      console.error(e);
      this.snackBar.open('An error has ocurred!');
    }

    this.isSaving = false;
    this.configFormChild.markAsPristine();
    this.cdr.detectChanges();
  }
}
