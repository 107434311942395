import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DonationService {
  private donationSubject = new BehaviorSubject<any>(null);
  donation$ = this.donationSubject.asObservable();

  setDonation(donation: any) {
    this.donationSubject.next(donation);
  }

  getDonation() {
    return this.donationSubject.value;
  }
}
