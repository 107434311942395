<div
  *ngIf="initForm"
  [formGroup]="eventReportForm"
  class="p-2 border border-primary rounded-md"
>
  <tix-card-header class="pl-0 pt-1">
    <tix-card-title>Daily event report:</tix-card-title></tix-card-header
  >
  <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
    <!-- default -->
    <mat-form-field>
      <mat-label> Default recipient </mat-label>
      <input
        [type]="initForm[0].elementType"
        [placeholder]="initForm[0].placeholder"
        matInput
        [formControlName]="initForm[0].name"
      />
      <!-- todo: hint -->
    </mat-form-field>

    <!-- time -->
    <mat-form-field>
      <mat-label> Time </mat-label>
      <input
        [type]="initForm[1].elementType"
        [placeholder]="initForm[1].placeholder"
        matInput
        [formControlName]="initForm[1].name"
      />
    </mat-form-field>

    <!-- zone -->
    <mat-form-field>
      <mat-label>Zone</mat-label>
      <mat-select [formControlName]="initForm[2].name">
        <mat-option *ngFor="let zone of zones" [value]="zone">
          {{ zone }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <!-- other recipients -->
  <div class="p-2 mb-1 pt-0 border rounded-md border-gray-300">
    <span class="text-xs pb-1 ml-1 text-secondary/95">Other recipients</span>
    <mat-chip-list #chipList class="flex items-center w-full">
      <mat-chip
        *ngFor="let recipient of recipients"
        (removed)="remove(recipient)"
      >
        {{ recipient }}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
      <input
        [placeholder]="initForm[3].placeholder"
        class="text-md w-full"
        [name]="initForm[3].name"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="add($event)"
      />
    </mat-chip-list>
  </div>
  <div class="text-xs ml-1 mb-4 w-full text-secondary/95">
    {{ initForm[3].helpText }}
  </div>

  <!-- actions  -->
  <div class="w-full flex items-center justify-between">
    <button
      *ngIf="isSubscribed"
      tix-flat-border-button
      color="danger"
      class="mr-0 md:mr-5 md:mt-0"
      (click)="softDeleted()"
      [disabled]="isDeleting || isRequesting"
    >
      Unsubscribe {{ isDeleting ? '...' : '' }}
    </button>
    <div>
      <!-- empty div helps to aligh items -->
    </div>
    <div>
      <button
        *ngIf="!isSubscribed"
        tix-button
        (click)="save()"
        [disabled]="isRequesting || eventReportForm.invalid"
      >
        Subscribe {{ isRequesting ? '...' : '' }}
      </button>
      <button
        *ngIf="isSubscribed"
        tix-button
        (click)="update()"
        [disabled]="isRequesting || eventReportForm.invalid || isDeleting"
      >
        Update {{ isRequesting ? '...' : '' }}
      </button>
    </div>
  </div>
</div>
